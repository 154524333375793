import React from 'react';
import { motion } from 'framer-motion';
import { item, textanimation } from '../../../Animation';

interface questionSectionProps {
  heading: string;
  question: string;
  ErrorMsg?: string;
  children: JSX.Element;
  id: number;
  keydown?: React.KeyboardEventHandler<HTMLDivElement>;
}
function QuestionSection({ question, ErrorMsg, children, id, keydown }: questionSectionProps) {
  return (
    <motion.div variants={item} id={id.toString()} onKeyDown={keydown}>
      <h2
        className={
          'pr-10 mb-2 font-sans text-xl text-[#737373] font-semibold leading-[30px] max-w-[640px]'
        }
      >
        {question} <span className="text-red-500">*</span>{' '}
        {(id == 2 || id == 4 || id == 5) && ' (just an estimate)'}
      </h2>
      {children}
      {ErrorMsg && (
        <motion.div
          variants={textanimation}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
          className={`text-left text-xs md:text-sm text-red-500 ml-2`}
        >
          {ErrorMsg}
        </motion.div>
      )}
    </motion.div>
  );
}

export default QuestionSection;

import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import ProgressBar from './ProgressBar';
import CheckBoxes from './Checkboxes';
import Autocomplete from 'react-google-autocomplete';
import arrowIcon from '../../../../assets/arrow.svg';
import axios from 'axios';
import QuestionSection from './QuestionSection';
import {
  borrowAmountRange,
  estimateAmountRange,
  interestedIn,
  liveProperty,
  mortgageAmountRange
} from './AmountRange';
import PhoneNo from './PhoneNumberInput';
import { container, item } from '../../../Animation';
import { emailRegex, keyDown } from './Validation/EmailValidation';
import { formatPhoneNumber } from './Validation/PhoneNoValidation';
import Heading from './Heading';
import Swal from 'sweetalert2';
export interface ApplyNowProps {
  id: number;
  setId: React.Dispatch<React.SetStateAction<number>>;
  ObjectData: any;
}
function ScrollIntoView(id: string) {
  setTimeout(() => {
    document.getElementById(id)?.scrollIntoView({
      behavior: 'smooth'
    });
  }, 300);
}
function index() {
  const [id, setId] = useState(1);
  const localData: any =
    typeof window !== 'undefined' && window.sessionStorage?.getItem('nuborrow_page10');
  const sessionData = JSON?.parse(localData);
  const [data, setData] = useState(sessionData ? sessionData : {});
  const [userAddress, setUserAddress] = useState<string>(data?.current_address ?? '');
  const [address, setAddress] = useState<string>('');
  const [checkBoxId, setCheckBoxId] = useState(data?.property_value ?? '');
  const [checkBoxBorrowAmount, setCheckBoxBorrowAmount] = useState(data?.amount_to_borrow ?? '');
  const [checkBoxMortgage, setCheckBoxMortgage] = useState(data?.remaining_balance_mortgage ?? '');
  const [optionError, setOptionError] = useState<string>('');
  const [Name, setName] = useState<string>(data?.name);
  const name = Name && Name.split(' ');
  const [email, setEmail] = useState<string>(data?.email);
  const [phoneNoValue, setPhoneNoValue] = useState<string>(
    data?.phone_number ? data?.phone_number : ''
  );
  const [phoneNo, setPhoneNo] = useState<string>(data?.phone_number);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFocus, setIsFocus] = useState(false);
  // on change Address

  useEffect(() => {
    typeof window !== 'undefined' &&
      window.addEventListener(
        'beforeunload',
        function (e) {
          sessionStorage.removeItem('nuborrow_page10');
        },
        false
      );
  }, []);
  useEffect(() => {
    userAddress?.length > 1 && setIsFocus(true);
  }, [userAddress]);
  // onchange name
  const NameHandler = (e: any) => {
    setOptionError('');
    const { value } = e.target;
    const re = /^[a-zA-Z\s]+$/;
    if (value === '' || re.test(value)) {
      setName(value);
      return setData({ ...data, name: value });
    }
  };
  const NameKeyDown = (e: any) => {
    if (e?.key === 'Enter' && !data?.name) {
      return setOptionError(`Question, "Name" is required.`);
    }
    if (e?.key === 'Enter' && data?.name) {
      // setData({ ...data, name: value });
      keyDown(e);
    }
  };

  const handleAddress = (e: any) => {
    if (!data?.current_address && !address) {
      setOptionError(`Question, "Address" is required.`);
      return;
    }
    if (address && !data?.current_address) {
      setOptionError(`Enter Ontario Address`);
      return;
    }
    if (!data?.current_address && address) {
      setOptionError(`Question, "Address" is required.`);
      return;
    }
    if (e?.key === 'Enter' && data?.current_address) {
      keyDown(e);
    }
  };
  //onSubmit Email
  const SubmitEmail = (e: any) => {
    if (!email) {
      return setOptionError(`Question, "Email" is required.`);
    }
    if (!email?.match(emailRegex)) {
      return setOptionError('Invalid Email Address');
    }
    if (e?.key === 'Enter' && email && Name) {
      setData({ ...data, email: email });
      keyDown(e);
    }
  };
  // on change Email Value
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOptionError('');
    setEmail(e.target.value);
    setData({ ...data, email: e.target.value });
  };
  // onChange Contact No
  const handleChangeContactNo = (e: any) => {
    setOptionError('');
    setPhoneNoValue(formatPhoneNumber(e.target.value));
    setOptionError('');
    setPhoneNo(formatPhoneNumber(e.target.value));
  };
  // handle Submit phone
  const handlePhone = async (e: any) => {
    e.preventDefault();
    try {
      if (!phoneNo || !phoneNoValue || phoneNo?.length < 10) {
        delete data?.phoneNo;
        setData(data);
        if (!phoneNo) {
          return setOptionError(`Question, "Phone" is required.`);
        } else if (phoneNo?.length < 14) {
          return setOptionError('Phone Syntax is incorrect');
        }
      } else if (!Name) {
        return setOptionError(`Question, "Name" is required.`);
      } else if (!email) {
        return setOptionError(`Question, "Email" is required.`);
      } else {
        const resp = await checkPhoneIsValid();
        if (resp === 200) {
          if (e?.key === 'Enter' && resp == 200) {
            keyDown(e);
          }
          setData({ ...data, phone_number: phoneNo });
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  // validate Contact No
  const checkPhoneIsValid = async () => {
    const formData = new FormData();
    formData.append('contact_no', phoneNo);
    try {
      const { data } = await axios({
        method: 'post',
        url: 'https://dev.nu2morrow.com/crm/apis/user/contactvalidator',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      if (data?.status !== 200) {
        return alert('Invalid number');
      }

      return data?.status;
    } catch (err: any) {
      setIsLoading(false);
      setOptionError('Phone is invalid');
      //   setAlertError(err?.response?.data?.message);
      return;
    }
  };
  const handleOnChangeAddress = (place: any) => {
    setIsFocus(false);
    setOptionError('');
    let postcode = '';
    let state = '';
    let country = '';
    let city = '';
    if (!place || place?.formatted_address == '' || place?.formatted_address == undefined) {
      delete data?.current_address;
      delete data.city;
      delete data.country;
      delete data.zip_code;
      setData(data);
      setUserAddress('');
      return setOptionError(`Question, "Address" is required.`);
    } else {
      for (let component of place?.address_components) {
        const componentType = component?.types[0];
        switch (componentType) {
          case 'postal_code': {
            postcode = component.long_name;
            break;
          }
          case 'administrative_area_level_1': {
            state = component.short_name;
            break;
          }
          case 'country': {
            country = component.long_name;
            break;
          }
          case 'locality': {
            city = component.long_name;
            break;
          }
        }
      }
      setIsFocus(true);
      setUserAddress(place?.formatted_address);
      setData({
        ...data,
        city: city,
        state: state,
        zip: postcode,
        current_address: place?.formatted_address,
        country: country
      });
      setTimeout(() => {
        sessionStorage.setItem('nuborrow_page10', JSON.stringify(data));
      }, 300);
      return;
    }
  };
  const handleAddressChange = (e: any) => {
    setOptionError('');
    setAddress(e.target.value);
    if (!e.target.value || userAddress) {
      delete data.current_address;
      setUserAddress('');
      setData(data);
      return setOptionError(`Question, "Address" is required.`);
    }
    setOptionError('');
  };
  // form handler
  const SubmitContactForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (id === 1 && !data?.interested_rate) {
        return setOptionError(`Question, "Most interested in?" is required.`);
      }
      if (id === 2 && !data?.amount_to_borrow) {
        return setOptionError(
          `Question, "How much you would like to borrow?" (just an estimate) is required.`
        );
      }
      if (id === 3 && !data?.home_owner) {
        return setOptionError(`Question, "What type of home do you have?" is required.`);
      }
      if (id === 4 && !data?.property_value) {
        return setOptionError(
          `Question, "What is the estimated value of your property?" (just an estimate) is required.`
        );
      }
      if (id === 5 && !data?.remaining_balance_mortgage) {
        return setOptionError(
          `Question, "What is the remaining mortgage balance on this property?" (just an estimate)  is required.`
        );
      }
      if (id === 6) {
        if (!Name) {
          return setOptionError(`Question, "Name" is required.`);
        }
        if (!email) {
          return setOptionError(`Question, "Email" is required.`);
        }
        if (!email.match(emailRegex)) {
          return setOptionError('Invalid Email Address');
        }
        if (!phoneNo || phoneNo?.length < 13 || !phoneNoValue) {
          if (!phoneNo) {
            setOptionError(`Question, "Phone" is required.`);
          } else if (phoneNo?.length < 14) {
            return setOptionError('Phone Syntax is incorrect');
          }
        } else {
          setIsLoading(true);
          const resp = await checkPhoneIsValid();
          if (resp === 200) {
            setData({ ...data, phone_number: phoneNo });
            if (!data?.current_address && !address) {
              setOptionError(`Question, "Address" is required.`);
              setIsLoading(false);
              return;
            }
            if (address && data?.state !== 'ON') {
              setOptionError(`Enter Ontario Address`);
              setIsLoading(false);
              return;
            }
            if (!data?.current_address && address) {
              setOptionError(`Question, "Address" is required.`);
              setIsLoading(false);
              return;
            }
            setIsLoading(true);
            const formData: any = new FormData();
            formData.append('money_need', data?.amount_to_borrow);
            formData.append('propertyType', data?.home_owner);
            formData.append('home_value', data?.property_value);
            formData.append('mortgage_balance_owing', data?.remaining_balance_mortgage);
            formData.append('imp_to_us', data?.interested_rate);
            formData.append('place', data?.current_address ? data?.current_address : '');
            formData.append('city', data?.city ? data?.city : '');
            formData.append('state', data?.state ? data.state : '');
            formData.append('zipCode', data?.zip ? data.zip : '');
            formData.append('country', data?.country ? data.country : '');
            formData.append('name', name);
            formData.append('product', '0');
            formData.append('email', email);
            formData.append('phone', phoneNo);
            formData.append('lead_source', '59');
            formData.append('phone_number', phoneNo);
            sessionStorage.setItem('nuborrow_page10', JSON.stringify(data));

            for (const pair of formData.entries()) {
              // console.log(`${pair[0]}, ${pair[1]}`);
            }
            const res = await axios({
              method: 'post',
              url: 'https://dev.nu2morrow.com/crm/apis/lead/add_landingpage',
              data: formData,
              headers: { 'Content-Type': 'multipart/form-data' }
            });
            if (res.status === 200) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'You’re all set!',
                text: 'Our team is already working on your report.',

                customClass: {
                  popup: 'page10_popup'
                },
                inputAttributes: {
                  id: 'page10_popup'
                },
                showCancelButton: false,
                width: 400,
                confirmButtonColor: '#7059a7',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
              }).then((result) => {
                if (result.isConfirmed) {
                  sessionStorage.removeItem('nuborrow_page10');
                  setIsLoading(false);
                  window.location.href = 'https://nuborrow.com/';
                  return null;
                }
              });
              setIsLoading(false);
              sessionStorage.removeItem('nuborrow_page10');
            }
          } else {
            setIsLoading(false);
          }
        }
        return;
      }
      setId(id + 1);
      setOptionError('');
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const handleBorrowAmount = (e: string, id: number) => {
    if (id === 1) {
      data.interested_rate = e;
      setData(data);
      setTimeout(() => {
        setId(id + 1);
        sessionStorage.setItem('nuborrow_page10', JSON.stringify(data));
      }, 400);
    }
    if (id === 2) {
      data.amount_to_borrow = e;
      setData(data);
      setTimeout(() => {
        setId(id + 1);
        sessionStorage.setItem('nuborrow_page10', JSON.stringify(data));
      }, 400);
    }
    if (id === 3) {
      data.home_owner = e;
      setData(data);
      setTimeout(() => {
        setId(id + 1);
        sessionStorage.setItem('nuborrow_page10', JSON.stringify(data));
      }, 400);
    }
    if (id === 4) {
      data.property_value = e;
      setData(data);
      setTimeout(() => {
        setId(id + 1);
        sessionStorage.setItem('nuborrow_page10', JSON.stringify(data));
      }, 400);
    }
    if (id === 5) {
      data.remaining_balance_mortgage = e;
      setData(data);
      setTimeout(() => {
        setId(id + 1);
        sessionStorage.setItem('nuborrow_page10', JSON.stringify(data));
      }, 400);
    }
  };
  const handleBackButton = () => {
    if (id > 1) {
      setIsLoading(false);
      setOptionError('');
      setId(id - 1);
    }
  };
  useEffect(() => {
    ScrollIntoView('error_view');
  }, [optionError]);

  return (
    <>
      <Heading id={id} />
      <div className="flex flex-col justify-center items-center mt-14 mx-auto xl:w-8/12 w-full">
        <form
          className="bg-white  shadow-[0px_3px_18px_-2px_rgba(0,0,0,0.25)] w-11/12 mx-auto md:w-[500px] lg:w-[750px] overflow-x-hidden"
          onSubmit={SubmitContactForm}
        >
          <motion.div
            variants={container}
            initial="offscreen"
            animate="onscreen"
            className="overflow-hidden md:py-4 pl-4"
          >
            <div className={`flex flex-col justify-start ${id <= 5 ? 'py-5 pl-5' : 'p-5'}`}>
              {optionError && (
                <motion.div
                  id="error_view"
                  variants={item}
                  className="mb-4 pl-2 text-red-900 border-l-[10px] border-red-500 text-sm h-10 md:h-7 md:flex items-center"
                >
                  <ul className="flex flex-nowrap list-disc list-inside">
                    <li> {optionError}</li>
                  </ul>
                </motion.div>
              )}
              <div className={`${id <= 5 ? 'py-5 pl-5' : 'p-2'}`}>
                {id === 1 && (
                  <>
                    <QuestionSection question={'Most interested in?'} heading={''} id={id}>
                      <div className="h-[120px]">
                        {' '}
                        {interestedIn.map((label, index: number) => (
                          <CheckBoxes
                            id={label?.id}
                            key={index}
                            onClick={() => {
                              setData({ ...data, interested_rate: label?.name });
                              handleBorrowAmount(label.name, 1);
                              setId(id + 1);
                              setOptionError('');
                              setCheckBoxId(label?.name);
                            }}
                            title={label?.name}
                            checked={(data?.interested_rate || checkBoxId) === label?.name && true}
                            defaultValue={label?.name}
                          />
                        ))}
                      </div>
                    </QuestionSection>
                  </>
                )}
                {id === 2 && (
                  <QuestionSection
                    id={id}
                    question={'How much you would like to borrow?'}
                    heading={''}
                  >
                    <div className="overflow-y-auto scroller_color h-[166px]">
                      {borrowAmountRange.map((label, index: number) => (
                        <CheckBoxes
                          id={label?.id}
                          key={index}
                          onClick={() => {
                            handleBorrowAmount(label?.name, 2);
                            setId(id + 1);
                            setOptionError('');
                            setCheckBoxBorrowAmount(label?.name);
                            setData({ ...data, amount_to_borrow: label?.name });
                          }}
                          title={label?.name}
                          checked={
                            (data?.amount_to_borrow || checkBoxBorrowAmount) === label?.name && true
                          }
                          defaultValue={label?.name}
                        />
                      ))}
                    </div>
                  </QuestionSection>
                )}
                {id === 3 && (
                  <QuestionSection id={id} question={'What type of home do you have?'} heading={''}>
                    <div className="h-[160px]">
                      {liveProperty.map((label, index: number) => (
                        <CheckBoxes
                          id={label?.id}
                          key={index}
                          onClick={() => {
                            setData({ ...data, home_owner: label?.name });
                            handleBorrowAmount(label?.name, 3);
                            setId(id + 1);
                            setOptionError('');
                            setCheckBoxId(label?.name);
                          }}
                          title={label?.name}
                          checked={(data?.home_owner || checkBoxId) === label?.name && true}
                          defaultValue={label?.name}
                        />
                      ))}
                    </div>
                  </QuestionSection>
                )}
                {id === 4 && (
                  <QuestionSection
                    id={id}
                    question="What is the estimated value of your property?"
                    heading={''}
                  >
                    <div className="overflow-y-auto scroller_color h-[166px]">
                      {estimateAmountRange.map((label, index: number) => (
                        <CheckBoxes
                          id={label?.id}
                          key={index}
                          onClick={() => {
                            setData({ ...data, property_value: label?.name });
                            handleBorrowAmount(label?.name, 4);
                            setId(id + 1);
                            setOptionError('');
                            setCheckBoxId(label?.name);
                          }}
                          title={label?.name}
                          checked={(data?.property_value || checkBoxId) === label?.name && true}
                          defaultValue={label?.name}
                        />
                      ))}
                    </div>
                  </QuestionSection>
                )}
                {id === 5 && (
                  <QuestionSection
                    id={id}
                    question="What is the remaining mortgage balance on this property?"
                    heading={''}
                  >
                    <div className="overflow-y-auto scroller_color h-[166px]">
                      {mortgageAmountRange.map((label, index: number) => (
                        <CheckBoxes
                          id={label?.name}
                          key={index}
                          onClick={() => {
                            setData({
                              ...data,
                              remaining_balance_mortgage: label?.name
                            });
                            handleBorrowAmount(label?.name, 5);
                            setId(id + 1);
                            setOptionError('');
                            setCheckBoxMortgage(label?.name);
                          }}
                          title={label?.name}
                          checked={
                            (data?.remaining_balance_mortgage || checkBoxMortgage) ===
                              label?.name && true
                          }
                          defaultValue={label?.name}
                        />
                      ))}
                    </div>
                  </QuestionSection>
                )}

                {id === 6 && (
                  <motion.div variants={item}>
                    <>
                      <div className="flex flex-col items-center gap-6 w-full">
                        <div className="bg-white w-64 md:w-[328px] lg:w-[443px] rounded-full border-2">
                          <input
                            type="text"
                            value={Name && Name}
                            onKeyDown={NameKeyDown}
                            placeholder="Your Name"
                            tabIndex={1}
                            // required
                            onChange={NameHandler}
                            className="outline-none w-full rounded-full px-8 font-sans text-base pl-4 md:px-16 py-2"
                          />
                        </div>

                        <div
                          onKeyDown={(e) => e.key === 'Enter' && SubmitEmail(e)}
                          className="bg-white w-64 md:w-[328px] lg:w-[443px] rounded-full border-2"
                        >
                          <input
                            type="text"
                            value={email && email}
                            // required
                            tabIndex={2}
                            onChange={handleEmailChange}
                            className="outline-none w-full rounded-full px-8 font-sans text-base pl-4 md:px-16 py-2"
                            placeholder={'Your Email'}
                            onKeyDown={() => handleEmailChange}
                          />
                        </div>
                        <div
                          onKeyDown={(e) => e.key === 'Enter' && handlePhone(e)}
                          className="bg-white w-64 md:w-[328px] lg:w-[443px] rounded-full border-2"
                        >
                          <PhoneNo
                            maxLength={14}
                            type="phone"
                            value={phoneNoValue}
                            className="mx-0"
                            placeHolder={'Your Phone'}
                            keyDown={handlePhone}
                            focus={false}
                            onchange={handleChangeContactNo}
                          />
                        </div>
                        <div
                          className="bg-white w-64 md:w-[328px] overflow-x-auto lg:w-[443px] rounded-full border-2"
                          onKeyDown={(e) => {
                            if (e?.key === 'Enter') {
                              return handleAddress(e);
                            }
                          }}
                        >
                          <Autocomplete
                            apiKey="AIzaSyDKhj1caiJVMeNgBAqbOjV97q0oMyAuRiQ"
                            // style={{ width: '90%' }}
                            tabIndex={4}
                            className="outline-none rounded-full overflow-x-auto font-sans text-base px-4 md:px-16 py-2 w-full"
                            // value={address}
                            onPlaceSelected={handleOnChangeAddress}
                            placeholder="Your Address"
                            options={{
                              types: ['geocode', 'establishment'],
                              componentRestrictions: { country: 'ca' }
                            }}
                            // defaultValue={address}
                            defaultValue={userAddress && userAddress}
                            onChange={(e: any) => {
                              handleAddressChange(e);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  </motion.div>
                )}
              </div>
            </div>
          </motion.div>
          <div className="h-[60px] shadow-[0px_3px_18px_-2px_rgba(0,0,0,0.25)] bg-brand flex md:justify-between px-4 md:px-8 text-xlitems-center text-white">
            <div
              id="page10_previous_btn"
              className={`cursor-pointer w-full flex justify-start items-center  ${
                id > 1 ? 'opacity-100' : 'opacity-0'
              }`}
              onClick={handleBackButton}
            >
              <img src={arrowIcon} className="mr-2 rotate-180 w-5" alt="icon" />
              Previous
            </div>
            <div className=" h-full w-[1.5px] bg-gray-50/60 font-semibold text-center"></div>
            {isLoading ? (
              <div className="w-full flex justify-end items-center">
                <div
                  className={`inline-block h-9 w-9 animate-spin rounded-full border-4 border-solid border-white border-t-transparent`}
                />
              </div>
            ) : (
              <>
                {id <= 5 ? (
                  <button
                    id="page10_next_btn"
                    type="submit"
                    className="cursor-pointer w-full flex justify-end items-center"
                  >
                    Next
                    <img src={arrowIcon} className="ml-2 w-5" alt="icon" />
                  </button>
                ) : (
                  <button
                    id="page10_btn_submit"
                    type="submit"
                    className="cursor-pointer w-full flex justify-end items-center"
                  >
                    Submit
                    <img src={arrowIcon} className="ml-2 w-5" alt="icon" />
                  </button>
                )}
              </>
            )}
          </div>
        </form>
        <ProgressBar id={id} setId={setId} />
      </div>
    </>
  );
}
export default index;

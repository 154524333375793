import type { HeadFC } from 'gatsby';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Page10 from '../components/InteractivePart/Page10/funnel/index';

const sizeClass = 'mx-auto bg-background';
const pageStyles = {
  backgroundColor: 'white'
};
function equity_heloc() {
  return (
    <main style={pageStyles}>
      <Header className={` mx-auto`} />
      <div className={`container mx-auto`}>
        <section className="flex flex-col xl:flex-row-reverse gap-8 lg:gap-16">
          <div className="flex-1 md:py-6 lg:py-16 ">
            <Page10 />
          </div>
        </section>
      </div>
      <Footer className={sizeClass} />
    </main>
  );
}

export default equity_heloc;

export const Head: HeadFC = () => <title>Nuborrow.com | Home Equity Made Easy</title>;
